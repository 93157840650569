<template>
  <div class="top-menu logged" v-if="shouldAppear">
    <nav class="navbar navbar-expand-lg navbar-dark container">
      <template v-if="mobile && showMenuOptions">
        <router-link
          class="navbar-brand"
          :to="$t('routes.home_logged')"
          @click="closeFloatingMenu"
        >
          <flix-latino-logo></flix-latino-logo>
        </router-link>
        <v-btn
          :ripple="false"
          size="x-small"
          variant="text"
          class="show-floating-menu"
          @click="showFloatingMenu"
        >
          <span>{{ $t("floatingmenu.browse") }}</span>
          <icon-arrow-up v-if="floatingMenu" />
          <icon-arrow-down v-else />
        </v-btn>
        <router-link class="search-mobile-icon" to="/search" v-if="!isKid">
          <icon-search />
        </router-link>
        <div class="pointer" v-bind="props">
          <router-link to="/profiles">
            <v-avatar>
              <img :src="currentProfile.image" :alt="currentProfile.name" />
            </v-avatar>
          </router-link>
        </div>
      </template>
      <template v-else-if="showMenuOptions">
        <router-link
          class="navbar-brand"
          :to="$t('routes.home_logged')"
          @click="closeFloatingMenu"
        >
          <flix-latino-logo></flix-latino-logo>
        </router-link>
      </template>
      <template v-else>
        <div class="navbar-brand">
          <flix-latino-logo></flix-latino-logo>
        </div>
        <ul class="navbar-nav ml-auto right-nav">
          <li class="nav-item m-2">
            <router-link class="text-light" to="/logout">
              {{ $t("navbar.logout") }}
            </router-link>
          </li>
        </ul>
      </template>

      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        v-if="showMenuOptions"
      >
        <ul class="navbar-nav mr-auto left-nav">
          <template v-if="showExtraLinks && !isKid">
            <li>
              <router-link
                class="text-white"
                :to="$t('routes.browse_movies')"
                >{{ $t("navbar.logged.movies") }}</router-link
              >
            </li>
            <li>
              <router-link
                class="text-white"
                :to="$t('routes.browse_series')"
                >{{ $t("navbar.logged.series") }}</router-link
              >
            </li>
            <li>
              <router-link class="text-white" :to="{ name: 'live' }">{{
                $t("navbar.logged.live")
              }}</router-link>
            </li>
            <li>
              <router-link class="text-white" :to="$t('routes.my_list')">{{
                $t("navbar.logged.my_list")
              }}</router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                :to="$t('routes.browse_by_countrie')"
                >{{ $t("navbar.logged.by_country") }}</router-link
              >
            </li>
          </template>
          <template v-else>
            <li>
              <router-link class="text-white" :to="{ name: 'live' }">{{
                $t("navbar.logged.live")
              }}</router-link>
            </li>
          </template>
          <li v-if="showItems">
            <router-link class="nav-link" :to="$t('routes.discover')">
              {{ $t("navbar.discover") }}
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <template v-if="isLogged">
            <li class="nav-item m-2" v-if="showExtraLinks">
              <router-link to="/search">
                <icon-search />
              </router-link>
            </li>
            <li class="nav-item">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <!--                                    <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>-->
                  <div class="pointer">
                    <router-link to="/profiles">
                      <v-avatar>
                        <img
                          :src="currentProfile.image"
                          :alt="currentProfile.name"
                        />
                      </v-avatar>
                    </router-link>
                    <icon-arrow-down v-bind="props" />
                  </div>
                </template>

                <v-list>
                  <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-title @click="popupClick(item)">{{
                      item.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </li>
          </template>
          <template v-else>
            <li class="nav-item">
              <router-link class="nav-link" to="/login">
                {{ $t("navbar.login") }}
              </router-link>
            </li>
            <li class="nav-item" v-if="showItems">
              <router-link class="btn btn-light" to="/register">
                {{ $t("navbar.subscribe") }}
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </nav>
    <div class="floating-menu" v-if="floatingMenu">
      <template v-if="!isKid">
        <v-list>
          <v-list-item @click="showMovies">
            <template v-slot:prepend>
              <icon-movie2 class="mr-3" />
            </template>
            <v-list-item-title>{{
              $t("floatingmenu.movies")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showSeries">
            <template v-slot:prepend>
              <icon-tv1 class="mr-3" />
            </template>
            <v-list-item-title>{{
              $t("floatingmenu.series")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showLive">
            <template v-slot:prepend>
              <icon-live class="mr-3" />
            </template>
            <v-list-item-title>{{ $t("floatingmenu.live") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showMyList">
            <template v-slot:prepend>
              <icon-plus class="mr-3" />
            </template>
            <v-list-item-title>{{
              $t("floatingmenu.my_list")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showCountries">
            <template v-slot:prepend>
              <icon-countries class="mr-3" />
            </template>
            <v-list-item-title>{{
              $t("floatingmenu.countries")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
      <template v-else>
        <v-list>
          <v-list-item @click="showLive">
            <template v-slot:prepend>
              <icon-live class="mr-3" />
            </template>
            <v-list-item-title>{{ $t("floatingmenu.live") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import FlixLatinoLogo from "@/components/icons/flixLatinoLogo.vue";
import IconArrowDown from "@/components/icons/iconArrowDown.vue";
import { mapState } from "pinia";
import { UserStore } from "@/stores/userStore";
import { ProfileStore } from "@/stores/profileStore";
import IconSearch from "@/components/icons/iconSearch.vue";
import IconArrowUp from "@/components/icons/iconArrowUp.vue";
import IconMovie2 from "@/components/icons/iconMovie2.vue";
import IconTv1 from "@/components/icons/iconTv1.vue";
import IconLive from "@/components/icons/iconLive.vue";
import IconPlus from "@/components/icons/iconPlus.vue";
import IconCountries from "@/components/icons/iconCountries.vue";

export default {
  name: "TopMenu",
  components: {
    IconCountries,
    IconPlus,
    IconLive,
    IconTv1,
    IconMovie2,
    IconArrowUp,
    IconSearch,
    IconArrowDown,
    FlixLatinoLogo,
  },
  computed: {
    ...mapState(UserStore, [
      "getAccount",
      "isEntitled",
      "getToken",
      "trialEntitlement",
    ]),
    ...mapState(ProfileStore, ["getCurrentProfile", "getProfiles"]),
    showMenuOptions() {
      if (this.$route.meta.hideMenuOptions === undefined) {
        return true;
      }
      return (
        this.getAccount.validdate === null &&
        !this.$route.meta.hideMenuOptions === true
      );
    },
    whereAmI() {
      return this.$route.name;
    },
    isKid() {
      return this.getCurrentProfile.is_kid === "true";
    },
    currentProfile() {
      return this.getCurrentProfile;
    },
    shouldAppear() {
      let appear = true;
      if (this.isMobile()) {
        appear = !["profiles", "search"].includes(this.whereAmI);
      } else {
        appear = !["profiles"].includes(this.whereAmI);
      }
      return appear;
    },
    mobile() {
      return this.isMobile();
    },
    isLogged() {
      return this.getToken !== null && this.getToken !== "";
    },
    account() {
      return this.getAccount;
    },
    showExtraLinks() {
      if (this.$route.name === "profiles") {
        return false;
      }
      return this.isEntitled || this.trialEntitlement;
    },
    showItems() {
      if (this.isLogged) {
        return false;
      }
      return this.$route.name !== "register";
    },
  },
  methods: {
    showMovies() {
      this.$router.push({ name: "browse-movies" });
      this.closeFloatingMenu();
    },
    showSeries() {
      this.$router.push({ name: "browse-series" });
      this.closeFloatingMenu();
    },
    showLive() {
      this.$router.push({ name: "live" });
      this.closeFloatingMenu();
    },
    showMyList() {
      this.$router.push({ name: "my-list" });
      this.closeFloatingMenu();
    },
    showCountries() {
      this.$router.push({ name: "browse-by-country" });
      this.closeFloatingMenu();
    },
    closeFloatingMenu() {
      this.floatingMenu = false;
    },
    showFloatingMenu() {
      this.floatingMenu = !this.floatingMenu;
    },
    switchProfile(profile) {
      this.$bus.emit("switchProfile", profile);
    },
    popupClick(item) {
      if (item.type === "internal") {
        this.$router.push({ name: item.route });
      } else if (item.type === "external") {
        const newTab = window.open(item.route, "_blank");
        if (newTab === null) {
          // Handle the error
          window.location.href = item.route;
        } else {
          // The new tab was successfully opened
          console.log("New tab opened");
        }
      }
    },
  },
  data() {
    return {
      floatingMenu: false,
      items: [
        {
          title: this.$t("navbar.logged.preferences"),
          route: "account",
          type: "internal",
        },
        {
          title: this.$t("navbar.logged.help"),
          route: "https://help.flixlatino.com/",
          type: "external",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors.scss";
.top-menu {
  &.logged {
    .left-nav {
      margin-top: 0.5em;
      li {
        font-size: 0.8em;
        padding: 0 1em;
      }
    }
  }
  .search-mobile-icon {
    width: 1em;
    margin: 0 1em 0 0;
  }
  .show-floating-menu {
    .v-btn__overlay {
      opacity: 0 !important;
    }
  }
  .floating-menu {
    //border: 1px solid #6692ab;
    background-color: #0e152499;
    backdrop-filter: blur(50px);
    width: 100vw;
    height: auto;
    position: absolute;
    //top: 95px;
    left: 0;
    z-index: 99;
    text-align: left;

    .closing-button {
      margin: 1em;
    }
    .v-list {
      background: none !important;
      .v-list-item {
        padding: 2em 1em;
      }
    }
  }
}
.profile-items {
  background: linear-gradient(139deg, #0b1019 0%, #0e1626 100%) !important;

  .profile-item-mobile {
    white-space: nowrap;
    width: 60vw;
    .avatar {
      width: 2em;
      height: 2em;
      float: left;
      margin-right: 0.5em;
    }
    span {
      display: block;
      padding: 0.3em 0.5em;
    }
  }
  hr {
    margin: 0.5em 0;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
  }
}
</style>
