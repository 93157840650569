<template>
  <div class="text-center lang-switcher" v-bind:class="{ compress: small }">
    <v-menu open-on-click>
      <template v-slot:activator="{ props }">
        <v-btn variant="outlined" v-bind="props">
          {{
            small
              ? $t("landing.language.current_code")
              : $t("landing.language.current")
          }}
          <template v-slot:append>
            <icon-arrow-down class="ml-5" />
          </template>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in langs"
          :key="index"
          @click.prevent="switchLang(item)"
        >
          <a class="text-white">{{ small ? item.code : item.title }}</a>

          <!--                    <v-list-item-title @click="switchLang(item)">{{-->
          <!--                      small ? item.code : item.title-->
          <!--                    }}</v-list-item-title>-->
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import IconArrowDown from "../icons/iconArrowDown.vue";
import { UserStore } from "@/stores/userStore";
import { mapActions } from "pinia";

import esRoutes from "@/functional/route_translations/en_routes";
import enRoutes from "@/functional/route_translations/es_routes";
import { i18n } from "@/functional/languages";

export default {
  components: { IconArrowDown },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    langs() {
      return [
        {
          title: this.$t("landing.language.current"),
          code: this.$t("landing.language.current_code"),
          url: this.lookfortheRouteKey(
            this.$t("landing.language.current_code")
          ),
        },
        {
          title: this.$t("landing.language.other"),
          code: this.$t("landing.language.other_code"),
          url: this.lookfortheRouteKey(this.$t("landing.language.other_code")),
        },
      ];
    },
  },
  methods: {
    ...mapActions(UserStore, ["setLanguage"]),
    switchLang(lang) {
      this.$i18n.locale = lang.code;
      this.setLanguage(lang.code);
      this.$bus.emit("langChanged", lang.code);
      let htmlTag = document.documentElement;
      htmlTag.setAttribute("lang", lang.code);
      let targetKey = this.lookfortheRouteKey(lang.code);
      if (targetKey) {
        window.location.href = targetKey;
      } else {
        try {
          this.$router
            .replace({
              params: { locale: lang.code },
            })
            .then(() => {
              if (
                typeof this.$route.matched[1].components.default === "function"
              ) {
                console.log(
                  "typeof this.$route.matched[1].components.default",
                  typeof this.$route.matched[1].components.default
                );
                window.location.reload();
              }
            });
        } catch (e) {
          console.log("catch", e);
          window.location.reload();
        }
      }
    },
    lookfortheRouteKey(lang) {
      let fullUrl = new URL(window.location.href);
      fullUrl.search = "";

      let targetKey = null;
      // Look for static urls
      Object.keys({ ...esRoutes.routes, ...enRoutes.routes }).forEach((key) => {
        if (
          esRoutes.routes[key] === fullUrl.pathname ||
          esRoutes.routes[key] === "/es" + fullUrl.pathname
        ) {
          targetKey = "routes." + key;
        }
        if (
          enRoutes.routes[key] === fullUrl.pathname ||
          esRoutes.routes[key] === "/en" + fullUrl.pathname
        ) {
          targetKey = "routes." + key;
        }
      });

      if (!targetKey) {
        // Look for dynamic urls
        let es = esRoutes;
        let en = enRoutes;
        delete es.routes;
        delete en.routes;
        delete es.trending;
        delete en.trending;

        let dynamicTarget = this.matchRoute(fullUrl.pathname, es);
        if (!dynamicTarget.key) {
          dynamicTarget = this.matchRoute(fullUrl.pathname, en);
        }

        if (dynamicTarget.key !== null) {
          return this.$t(dynamicTarget.key, lang, {
            slug: dynamicTarget.slug,
            season: dynamicTarget.season,
            episode: dynamicTarget.episode,
          });
        } else {
          return i18n.global.t("browse_by_country");
        }
      }

      return this.$t(targetKey, lang);
    },
    matchRoute(path, routePatterns) {
      // This function converts a route pattern into a regular expression
      function createRegExpFromPattern(pattern) {
        if (typeof pattern === "string") {
          let escapedPattern = pattern.replace(
            /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
            "\\$&"
          );

          escapedPattern = escapedPattern.replace(/\\\{slug\\\}/g, "([^\\/]+)");
          escapedPattern = escapedPattern.replace(/\\\{season\\\}/g, "(\\d+)");
          escapedPattern = escapedPattern.replace(/\\\{episode\\\}/g, "(\\d+)");
          return new RegExp("^" + escapedPattern + "$");
        } else {
          return new RegExp("^$");
        }
      }

      const result = {
        key: null,
        slug: null,
        season: null,
        episode: null,
      };

      for (const key in routePatterns) {
        const pattern = routePatterns[key];
        const regex = createRegExpFromPattern(pattern);

        const match = regex.exec(path);

        if (match) {
          result.key = key;

          const placeholders = pattern.match(/\{[^}]+\}/g) || [];

          placeholders.forEach((placeholder, index) => {
            const value = match[index + 1];
            if (placeholder === "{slug}") {
              result.slug = value;
            } else if (placeholder === "{season}") {
              result.season = value;
            } else if (placeholder === "{episode}") {
              result.episode = value;
            }
          });

          break;
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors.scss";

.lang-switcher {
  width: 100%;
  .v-btn {
    justify-content: space-between;
  }
  .v-btn__overlay {
    background-color: transparent !important;
  }
  .v-btn__content {
    grid-area: auto;
    color: white !important;
  }
  .v-btn {
    width: 100%;
    margin-top: 2em;
    border-color: rgba(255, 255, 255, 0.5);
  }
  &.compress {
    .v-btn {
      margin-top: 0;
      border: none;
      height: 3em !important;
    }
  }
}
.v-theme--flixlatinotheme {
  &.v-list {
    .v-list-item {
      &:hover {
        background-color: $washed-white !important;
        font-weight: bold;
        cursor: pointer;

        .text-white {
          color: #1a2338 !important;
          z-index: 99;
          position: relative;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
