//@ts-nocheck
import Cookie from "@/services/Cookie";

(function (t, a, p) {
  t.TapfiliateObject = a;
  t[a] =
    t[a] ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (t[a].q = t[a].q || []).push(arguments);
    };
})(window, "tap");
tap("create", "38700-b1716f", { integration: "javascript" });
tap("detect", { always_callback: true }, (error, result) => {
  if (error === null) {
    Cookie.createTapAffiliateClick(result.id);
  }
});
