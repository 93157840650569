<template>
  <picture>
    <source srcset="/img/logo-white-140x33.png.webp" type="image/webp" />
    <source srcset="/img/logo-white-140x33.png" type="image/png" />
    <img
      src="/img/logo-white-140x33.png"
      alt="FlixLatino Logo"
      width="140"
      height="33"
      class="img-fluid"
      style="margin: 0.5em"
    />
  </picture>
</template>
<script>
export default {
  name: "FlixLatinoLogo",
};
</script>
