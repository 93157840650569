<template>
  <svg id="svg5" x="0px" fill="#fff" y="0px" viewBox="0 0 1900.56 1721.19">
    <g id="layer1" transform="translate(52.390088,-25.058597), scale(1.5)">
      <path
        id="path1009"
        d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
		h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"
      />
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
