// @ts-ignore
Array.prototype.first = function () {
  return this[0];
};
// @ts-ignore
Array.prototype.take = function (N) {
  if (N <= 0) {
    return [];
  } else if (N >= this.length) {
    return this.slice();
  } else {
    return this.slice(0, N);
  }
};

// @ts-ignore
Array.prototype.joinEpisodes = function () {
  const allEpisodes: any[] = [];

  this.forEach((season) => {
    if (Array.isArray(season.episodes)) {
      allEpisodes.push(...season.episodes);
    }
  });
  // @ts-ignore
  return allEpisodes;
};

interface String {
  toHumanReadableDate(locale: string): string;
}

String.prototype.toHumanReadableDate = function (locale: string): string {
  const date = new Date(this.replace(" ", "T"));
  const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric" };
  return date.toLocaleDateString(locale, options);
};

// @ts-ignore
String.prototype.toMinutes = function () {
  // @ts-ignore
  return Math.round(this / 60, 0) + " min";
};

// @ts-ignore
String.prototype.toHHMMSS = function () {
  // @ts-ignore
  const secNum = parseInt(this, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;
  console.log(secNum);
  return [hours, minutes, seconds].map((v) => (v < 10 ? "0" + v : v)).join(":");
};

// @ts-ignore
String.prototype.toHour = function () {
  // @ts-ignore
  const date = new Date(this);
  // @ts-ignore
  let hour = date.getHours();
  let period = "am";

  if (hour === 0) {
    hour = 12;
  } else if (hour === 12) {
    period = "pm";
  } else if (hour > 12) {
    hour -= 12;
    period = "pm";
  }

  // @ts-ignore
  const minute = String(date.getMinutes()).padStart(2, "0");
  // @ts-ignore
  return hour + ":" + minute + period;
};

// @ts-ignore
Array.prototype.startFrom = function (season, episode) {
  const startIndex = this.findIndex(
    (item) =>
      item.sources[0].season === season && item.sources[0].episode === episode
  );
  if (startIndex !== -1) {
    return this.slice(startIndex);
  }
  return [];
};

// @ts-ignore
Array.prototype.startFromAsset = function (season, episode) {
  const startIndex = this.findIndex(
    (item) => item.season === season && item.episode === episode
  );
  if (startIndex !== -1) {
    return this.slice(startIndex);
  }
  return [];
};

// @ts-ignore
Array.prototype.getFirstNonNull = function () {
  for (const key in this) {
    // @ts-ignore
    if (this[key] !== null) {
      // @ts-ignore
      return this[key];
    }
  }
  return this;
};
