<template>
  <div class="container">
    <flix-latino-logo class="mx-auto mt-5 mb-3 d-block" />
    <p class="text-center">
      En estos momentos nuestro servicio solo está disponible en la internet de
      los Estados Unidos y Puerto Rico.
    </p>
  </div>
</template>

<script>
import FlixLatinoLogo from "@/components/icons/flixLatinoLogo.vue";
export default {
  name: "NotUs",
  components: {
    FlixLatinoLogo,
  },
};
</script>
