class MetadataHandler {
  private metaTags: HTMLMetaElement[] = [];
  private domain = "https://flixlatino.com";
  constructor() {}

  public addOrUpdateMetaTag(
    property: string | null,
    name: string | null,
    content: string | null,
    id: string | null
  ): void {
    const key = property || name;
    let existingTag = null;
    if (name) {
      existingTag = this.findMetaTagByName(name);
    } else {
      // @ts-ignore
      existingTag = this.findMetaTagByProperty(property);
    }

    if (existingTag) {
      if (content) {
        existingTag.content = content;
      } else {
        existingTag.removeAttribute("content");
      }
      if (id) {
        existingTag.id = id;
      } else {
        existingTag.removeAttribute("id");
      }
      if (property) {
        existingTag.setAttribute("property", property);
      } else {
        existingTag.removeAttribute("property");
      }
    } else {
      const newTag = document.createElement("meta");
      if (name) {
        newTag.name = name;
      }
      if (content) {
        newTag.content = content;
      }
      if (id) {
        newTag.id = id;
      }
      if (property) {
        newTag.setAttribute("property", property);
      }
      document.head.appendChild(newTag);
      this.metaTags.push(newTag);
    }
  }

  public removeMetaTag(name: string): void {
    const tagToRemove = this.findMetaTagByName(name);
    if (tagToRemove) {
      document.head.removeChild(tagToRemove);
      this.metaTags = this.metaTags.filter((tag) => tag !== tagToRemove);
    }
  }

  private findMetaTagByName(name: string): HTMLMetaElement | undefined {
    return this.metaTags.find((tag) => tag.name === name);
  }

  private findMetaTagByProperty(property: string): HTMLMetaElement | undefined {
    return this.metaTags.find(
      (tag) => tag.getAttribute("property") === property
    );
  }

  public update(asset: any, $t: any): void {
    const trailer = asset.trailer.replace(".m3u8", "/out.mp4");

    const metaDescription = $t(
      asset.type === "series"
        ? "meta.series.description"
        : "meta.movie.description",
      { title: asset.title }
    );
    const metaTitle = $t(
      asset.type === "series" ? "meta.series.title" : "meta.movie.title",
      { title: asset.title }
    );
    const pageTitle = $t(
      asset.type === "series"
        ? "meta.series.title"
        : "meta.movie.title",
      { title: asset.title }
    );

    const metaAsset = [
      {
        name: "robots",
        content:
          "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
      },
      {
        property: "og:title",
        content: metaTitle,
      },
      {
        property: "og:description",
        content: metaDescription,
      },
      {
        property: "og:url",
        content: "https://flixlatino.com/" + asset.type + "/" + asset.slug,
        id: "meta-url",
      },
      {
        property: "og:image",
        content: asset.desktop,
      },
      {
        property: "og:video",
        content: trailer,
      },
      {
        property: "og:video:secure_url",
        content: trailer,
      },
      { property: "og:type", content: "video.movie" },
      { property: "og:video:type", content: "video/mp4" },
      { property: "al:ios:app_store_id", content: "1113757930" },
      { property: "al:ios:app_name", content: "FlixLatino" },
      { property: "al:android:package", content: "com.trespixels.flixlatino" },
      { property: "al:android:app_name", content: "FlixLatino" },
      {
        property: "description",
        content: metaDescription,
      },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: "@flixlatino" },
      {
        name: "keywords",
        content: metaDescription,
      },
      {
        name: "description",
        content: metaDescription,
      },
    ];
    document.title = pageTitle;
    if (typeof asset.metadata !== "undefined") {
      if (typeof asset.metadata.video_script !== "undefined") {
        const videoTag = document.getElementById("videoScript");
        // @ts-ignore
        videoTag.textContent = JSON.stringify(asset.metadata.video_script);
      }

      if (typeof asset.metadata.asset_script !== "undefined") {
        const assetTag = document.getElementById("assetScript");
        // @ts-ignore
        assetTag.textContent = JSON.stringify(asset.metadata.asset_script);
      }
      this.canonicalSet(asset, $t);
    }

    metaAsset.forEach((meta) => {
      this.addOrUpdateMetaTag(
        meta.property || null,
        meta.name || null,
        meta.content || null,
        meta.id || null
      );
    });
  }
  public updateTitleAndDescription(title: string, description: string): void {
    document.title = title;
    this.addOrUpdateMetaTag(null, "description", description, null);
  }
  public canonicalSet(asset: any, $t: any): void {
    if (!asset) {
      return;
    }

    let urlEs = "";
    let urlEn = "";
    switch (asset.type) {
      case "series":
        urlEs = $t("serie_route", "es", { slug: asset.slug });
        urlEn = $t("serie_route", "en", { slug: asset.slug });
        break;
      case "movie":
        urlEs = $t("movie_route", "es", { slug: asset.slug });
        urlEn = $t("movie_route", "en", { slug: asset.slug });
        break;
    }

    this.canonicalUrls(urlEs, urlEn);
  }

  public canonicalUrls(es: string, en: string, can?: string): void {
    const langEs = document.getElementById("langEs");
    const langEn = document.getElementById("langEn");
    const langDef = document.getElementById("langDef");
    const langCan = document.getElementById("langCan");

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.search = "";
    const canonical = url.toString();

    if (langEs !== null) {
      langEs.setAttribute("href", this.domain + es);
    }
    if (langDef !== null) {
      langDef.setAttribute("href", this.domain + es);
    }
    if (langCan !== null) {
      langCan.setAttribute("href", canonical);
    }
    if (langEn !== null) {
      langEn.setAttribute("href", this.domain + en);
    }
  }
}
export default new MetadataHandler();
