<template>
  <div
    class="header"
    v-bind:class="{ pinned: scrolled, 'fixed-top': !logoShow }"
  >
    <nav class="navbar navbar-expand-lg navbar-dark container p-md-1 p-0">
      <router-link
        class="navbar-brand"
        :to="
          getFreemiumWorkflow === false ? $t('routes.home') : $t('routes.free')
        "
        @click.stop="hideMenu()"
      >
        <flix-latino-logo v-if="logoShow"></flix-latino-logo>
        <logo-f v-else />
      </router-link>
      <router-link
        class="btn btn-danger toptrial"
        :to="$t('routes.register')"
        v-if="!logoShow"
      >
        {{ $t("navbar.trial") }}
      </router-link>
      <div
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <router-link
          v-if="!isLogged && logoShow"
          class="logoutbutton mr-3"
          :to="$t('routes.login')"
          @click.stop="hideMenu()"
        >
          {{ $t("navbar.login") }}
        </router-link>

        <template v-if="isLogged">
          <router-link class="logoutbutton" :to="$t('routes.logout')">
            {{ $t("navbar.logout") }}
          </router-link>
        </template>
        <icon-menu
          v-else-if="getFreemiumWorkflow === false"
          @click="toggleMenu()"
        ></icon-menu>
      </div>
      <div
        v-bind:class="{ show: show }"
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav mr-auto tex">
          <li class="nav-item active tex" v-if="showItems">
            <router-link
              class="nav-link"
              :to="$t('routes.discover')"
              @click.stop="hideMenu()"
            >
              {{ $t("navbar.discover") }}
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <template v-if="isLogged">
            <li class="nav-item">
              <router-link
                @click.stop="hideMenu()"
                class="nav-link text-right"
                :to="$t('routes.logout')"
              >
                <span class="float-down">{{ $t("navbar.logout") }}</span>
                {{ account.name ?? account.email }}
              </router-link>
            </li>
          </template>
          <template v-else>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="$t('routes.login')"
                @click.stop="hideMenu()"
              >
                {{ $t("navbar.login") }}
              </router-link>
            </li>
            <li class="nav-item" v-if="showItems">
              <router-link
                class="btn btn-light"
                :to="$t('routes.register')"
                @click.stop="hideMenu()"
              >
                {{ $t("navbar.subscribe") }}
              </router-link>
            </li>
          </template>
          <li class="nav-item" v-if="!isLogged">
            <switch-language-component
              v-if="!isLogged"
              :small="true"
              class="w-100"
            />
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import FlixLatinoLogo from "../icons/flixLatinoLogo.vue";
import { mapState } from "pinia";
import { UserStore } from "@/stores/userStore";
import IconMenu from "@/components/icons/iconMenu.vue";
import SwitchLanguageComponent from "@/components/forms/SwitchLanguageComponent.vue";
import LogoF from "@/components/icons/LogoF.vue";
import { SessionStore } from "@/stores/sessionStore";

export default {
  name: "landing-header",
  components: {
    SwitchLanguageComponent,
    IconMenu,
    FlixLatinoLogo,
    LogoF,
  },
  data() {
    return {
      show: false,
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
    };
  },
  // beforeMount() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  // beforeUnmount() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  methods: {
    hideMenu() {
      this.show = false;
    },
    toggleMenu() {
      this.show = !this.show;
      console.log("Show menu:", this.show);
    },
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        // move up!
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
        // move down
      }

      this.lastPosition = window.scrollY;
      // this.scrolled = window.scrollY > 250;
    },
  },
  computed: {
    ...mapState(UserStore, ["getAccount", "getToken"]),
    ...mapState(SessionStore, ["getFreemiumWorkflow"]),
    logoShow() {
      if (!this.isMobile()) {
        return true;
      }
      let ctaTopPages = ["peliculas", "series"];
      return !ctaTopPages.includes(this.$route.name);
    },
    isLogged() {
      return this.getToken !== null && this.getToken !== "";
    },
    account() {
      return this.getAccount;
    },
    showItems() {
      if (this.isLogged) {
        return false;
      }
      return this.$route.name !== "register";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors.scss";
.header {
  &.pinned {
    position: fixed;
    top: 0;
    background-color: $dark-blue;
    width: 100vw;
    padding: 1em 0 0;
    z-index: 99;
  }
  nav {
    .toptrial {
      margin: 0.8em 0;
    }
    .w-33 {
      width: 33px;
    }
    .w-48 {
      width: 48px;
    }
    .navbar-toggler {
      border: none;
      outline: none;
      .logoutbutton {
        color: #ffffff;
        font-size: 0.7em;
      }
    }
    padding: 2.35em;
    li.nav-item {
      margin: 0 0.5em;
      .float-down {
        position: absolute;
        margin-top: 1.7em;
        font-style: italic;
        font-size: 0.8em;
        color: $red;
      }
    }
    .dropdown-toggle::after {
      content: none;
    }
  }

  @media (max-width: 767px) {
    nav {
      padding: 1em;
    }

    #navbarSupportedContent {
      backdrop-filter: blur(10px);
      position: fixed;
      top: 4em;
      width: 100%;
      height: 100%;
      left: 0;
      padding: 1em 2em;
      z-index: 99;

      .nav-item {
        text-align: center;
        font-size: 1.5em;
        padding: 0.3em;

        &:active {
          a {
            border-radius: 0.5em;
            background-color: $washed-white;
          }
        }
      }
    }
  }
}
</style>
