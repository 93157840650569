<template>
  <metainfo></metainfo>
  <template v-if="disabledFence">
    <template v-if="isPlayer && !mobile">
      <RouterView />
    </template>
    <div v-else v-bind:class="{ mobile: mobile }">
      <div class="reloading" v-if="reloading"></div>
      <top-menu
        v-if="
          isEntitled || (trialEntitlement && isLoggedIn && !displayLogoutOnly)
        "
      ></top-menu>
      <landing-header v-else></landing-header>
      <RouterView />
      <footer-component v-if="!hideFooter" />
    </div>
  </template>
  <notus v-else />
</template>
<style lang="scss">
@keyframes pulse {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.5;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.reloading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #0c111b;
  z-index: 999;
  animation: pulse 8s infinite linear alternate;
}
</style>
<script>
import packageJson from "../package.json";
import { RouterView } from "vue-router";
import LandingHeader from "./components/generic/landingHeader.vue";
import FooterComponent from "./components/generic/FooterComponent.vue";
import { mapActions, mapState } from "pinia";
import { UserStore } from "./stores/userStore";
import { ProfileStore } from "./stores/profileStore";
import TopMenu from "./components/app/partials/TopMenu.vue";
import Bugsnag from "@bugsnag/js";
import Notus from "@/views/sales/notus.vue";
import axios from "axios";
import { FlixlatinoApiClient } from "@/services/FlixlatinoApiClient";

import { OrizonApiClient } from "@/services/OrizonApiClient";
import Cookie from "@/services/Cookie";
import { SessionStore } from "@/stores/sessionStore";

const orizonApiClient = new OrizonApiClient();

let currentRoute = undefined;

export default {
  name: "App",
  components: {
    Notus,
    TopMenu,
    LandingHeader,
    FooterComponent,
    RouterView,
  },
  beforeRouteEnter(to, from, next) {
    console.log("ROUTE", to);
    currentRoute = to.name;
    next();
  },
  computed: {
    ...mapState(UserStore, [
      "getLanguage",
      "isEntitled",
      "userAccount",
      "isLoggedIn",
      "getToken",
      "trialEntitlement",
    ]),
    ...mapState(ProfileStore, ["getCurrentProfile"]),
    ...mapState(SessionStore, ["getFreemiumWorkflow"]),
    disabledFence() {
      let routeList = ["terms-and-conditions", "privacy-policy"];
      if (routeList.includes(this.$route.name)) {
        return true;
      }
      return import.meta.env.DEV ? true : this.fenceAccess;
    },
    mobile() {
      return this.isMobile();
    },
    isPlayer() {
      return this.$route.matched[1]?.meta?.isPlayer;
    },
    version: () => packageJson.version,
    hideFooter() {
      let hideFooter = [
        "profiles",
        "profile-add",
        "search",
        "register",
        "checkout",
        "plans-logged",
      ];

      return hideFooter.includes(this.$route.name);
    },
    displayLogoutOnly() {
      return (
        this.$route.name === "register" ||
        this.$route.name === "checkout" ||
        this.$route.name === "plans-logged" ||
        this.$route.name === "profiles"
      );
    },
  },
  data() {
    return {
      fenceAccess: true,
      reloading: false,
      hitFence: null,
    };
  },
  created() {
    if (this.getLanguage !== this.$i18n.locale) {
      this.$i18n.locale = this.getLanguage;
      this.setLanguage(this.getLanguage);
    }
    this.reportInit();
    this.hitFence = setInterval(async () => {
      if (window.localStorage.getItem("data_enrichment") !== null) {
        this.checkFence();
        clearInterval(this.hitFence);
      }
    }, 2000);
    console.info("Current Version", this.version);
    console.info("Current Stored Lang", this.getLanguage);
    console.info("Current Locale Lang", this.$i18n.locale);

    setTimeout(() => {
      this.$i18n.locale = axios.defaults.params["lang"];
    }, 100);
  },
  mounted() {
    this.$bus.on("switchProfile", (profile) => {
      this.setProfile(profile);
    });
    this.$bus.on("langChanged", () => {
      if (this.isLoggedIn) {
        this.loggedInReload();
      }
    });
  },
  methods: {
    ...mapActions(UserStore, ["setLanguage", "setFlMark"]),
    loggedInReload() {
      this.reloading = true;
      let profile = this.getCurrentProfile;
      let apiClient = new FlixlatinoApiClient();
      apiClient.getInitData(profile).then(() => {
        // this.$bus.emit("reloading", false);
        this.reloading = false;
      });
    },
    getAllParameters() {
      const urlParams = new URLSearchParams(window.location.search);
      const parameters = {};

      const paramNames = [
        "traffic_type",
        "ga_client_id",
        "ga_session_id",
        "user_agent",
        "user_ip_address",
        "bot_score",
        "consent_status",
        "internal_campaign",
        "experiment_campaign_data",
        "microsoft_clarity_session_playback_url",
        "channel_grouping",
        "utm_id",
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_source_platform",
        "utm_content",
        "utm_creative_format",
        "utm_marketing_tactic",
        "utm_term",
        "gclid",
        "entrance_page",
        "channel_grouping_first_touch",
        "utm_id_first_touch",
        "utm_source_first_touch",
        "utm_medium_first_touch",
        "utm_campaign_first_touch",
        "utm_source_platform_first_touch",
        "utm_content_first_touch",
        "utm_creative_format_first_touch",
        "utm_marketing_tactic_first_touch",
        "utm_term_first_touch",
        "gclid_first_touch",
        "timestamp_first_touch",
        "entrance_page_first_touch",
        "microsoft_click_id",
        "twitter_click_id",
        "facebook_browser_id",
        "facebook_click_id",
        "tiktok_browser_id",
        "tiktok_click_id",
        "ref",
      ];

      paramNames.forEach((paramName) => {
        if (urlParams.has(paramName)) {
          parameters[paramName] = urlParams.get(paramName);
        }
      });

      return parameters;
    },
    objectToQueryString(obj) {
      return Object.entries(obj)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
    },
    checkFence() {
      let allUtms = this.getAllParameters();
      let queryString = `${this.objectToQueryString(allUtms)}`;
      queryString = queryString !== "" ? `${queryString}&` : "";
      let queryString2 =
        window.localStorage.getItem("data_enrichment") !== null
          ? `${this.objectToQueryString(
              JSON.parse(window.localStorage.getItem("data_enrichment"))
            )}`
          : "";

      let clarity =
        window.clarity_user_id !== undefined
          ? `clarity_user_id=${window.clarity_user_id}&`
          : "";

      if (allUtms["ref"] !== undefined) {
        Cookie.createTapAffiliateCookie(allUtms["ref"]);
      }

      orizonApiClient
        .get(`/api/?l=fence&${clarity}${queryString}${queryString2}`, true)
        .then((response) => {
          if (!response.data.error) {
            this.fenceAccess = response.data.access;
            this.markTheUser(response.data.fl_mark);
          }
        });
    },
    markTheUser(cookieValue) {
      this.setFlMark(cookieValue);
      var name = "fl_mark";
      var value = cookieValue;
      var expirationDays = 90;
      var domain = ".flixlatino.com";
      var path = "/";

      var expirationDate = new Date();
      expirationDate.setTime(
        expirationDate.getTime() + expirationDays * 24 * 60 * 60 * 1000
      );
      var expires = "expires=" + expirationDate.toUTCString();

      document.cookie =
        name +
        "=" +
        value +
        ";" +
        expires +
        ";path=" +
        path +
        ";domain=" +
        domain +
        ";Secure";
    },
    setProfile(profile) {
      this.$router.push({ name: "profiles" }).then(() => {
        this.$bus.emit("setProfile", profile);
      });
    },
    reportInit() {
      if (this.isLoggedIn) {
        window.bn = Bugsnag;
        // @ts-ignore
        const userId = this.userAccount.user_id;
        // @ts-ignore
        const username = this.userAccount.username;
        // @ts-ignore
        const name = this.userAccount.name;
        Bugsnag.setUser(userId, username, name);
      }
    },
  },
};
</script>
